//
// _choices.scss
//

.choices {
    position: relative;
    margin-bottom: 24px;
    font-size: 16px;

    &:focus {
        outline: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.is-disabled{
        .choices__inner,
        .choices__input {
            background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
            cursor: not-allowed;
            user-select: none;
        }

        .choices__item {
            cursor: not-allowed;
        }
    }

    [hidden] {
        display: none !important;
    }
    
    // data-type = select-one
    &[data-type*='select-one'] {
        cursor: pointer;
        .choices__inner{
            padding-bottom: 0.25rem;
        }

        .choices__input{
            display: block;
            width: 100%;
            padding: $input-padding-y $input-padding-x;
            background-color: $input-bg;
            border: 1px solid $input-border-color;
            font-size: $input-font-size;
            border-radius: $input-border-radius;
            color: $input-color;
            margin-bottom: 10px;
        }

        .choices__button{
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
            padding: 0;
            background-size: 8px;
            position: absolute;
            top: 50%;
            right: 0;
            left: auto;
            margin-top: -10px;
            margin-right: 25px;
            margin-left: 0;
            height: 20px;
            width: 20px;
            border-radius: 10em;
            opacity: 0.5;

            &:hover, &:focus {
                opacity: 1;
            }

            &:focus {
                box-shadow: 0px 0px 0px 2px #00bcd4;
            }
        }

        .choices__item[data-value=''] .choices__button {
            display: none;
        }

        &:after {
            content: "\F0140";
            position: absolute;
            border: 0;
            border-color: $dark transparent transparent;
            right: 11.5px;
            left: auto;
            font-family: 'Material Design Icons';
            width: auto;
            height: auto;
            margin-top: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        &.is-open:after {
            margin-top: 0;
            transform: translateY(-50%) rotate(-180deg);
        }

    }


    // data-type = select- multiple & text

    &[data-type*='select-multiple'],
    &[data-type*='text'] {
        .choices__inner {
            cursor: text;
            padding-right: #{$form-select-padding-x};
        }

        .choices__button {
            position: relative;
            display: inline-block;
            margin-top: 0;
            margin-right: -4px;
            margin-bottom: 0;
            margin-left: 8px;
            padding-left: 16px;
            border-left: 1px solid rgba($white, 0.5);
            border-right: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
            background-size: 8px;
            width: 8px;
            line-height: 1;
            opacity: 0.75;
            border-radius: 0;
            &:hover, &:focus {
                opacity: 1;
            }
        }
        .choices__list--dropdown{
            padding-bottom: 10px;
            .choices__list{
                margin-bottom: 0;
            }
        }
    }

    &[data-type*=select-multiple] {
        .choices__input {
            padding-top: 3px;
        }
    }
}

//Select Light
.input-light {
    .choices__inner { 
        background-color: var(--#{$prefix}light);
        border: none;
    }
}
  
.choices__inner {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    background-color: $form-select-bg;
    padding: 0.25rem calc(#{$form-select-padding-x} + #{$form-select-indicator-padding}) 0.1rem 0.5rem;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: $form-select-border-radius !important;
    font-size: $input-font-size;
    min-height: 37.5px;
    overflow: hidden;
}

  
.is-focused,
.is-open {
    .choices__inner {
        border-color: $input-focus-border-color;
    }
}
  
.choices__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}
  
.choices__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;

    .choices__item {
        width: 100%;
    }
}

.choices__list--multiple {
    display: inline;
    .choices__item {
        display: inline-block;
        vertical-align: initial;
        border-radius: 7px;
        padding: 2px 7px;
        font-size: 11px;
        font-weight: $font-weight-normal;
        margin-right: 3.75px;
        margin-bottom: 3.75px;
        margin-top: 2px;
        background-color: $primary;
        border: 1px solid $primary;
        word-break: break-all;
        box-sizing: border-box;
        color: $white;
        box-shadow: $element-shadow;
        &[data-deletable] {
            padding-right: 5px;
        }

        &.is-highlighted {
            background-color: $primary;
            border: 1px solid $primary;
        }
    }
}

  
.is-disabled {
    .choices__list--multiple {
        .choices__item {
            background-color: $secondary;
            border: 1px solid $secondary
        }
    }
}
  
.choices__list--dropdown {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-color);
    box-shadow: $dropdown-box-shadow;
    top: 100%;
    margin-top: 0;
    padding: 10px 10px 20px 10px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
    animation-duration: .3s;
    animation-fill-mode: both;
    transform: translateY(1px);
    &.is-active {
        visibility: visible;
        animation-name: DropDownSlide;
    }

    .choices__item--selectable.is-highlighted {
        background-color: var(--#{$prefix}tertiary-bg);
    }

    .choices__list {
        margin: 0 -16px -16px;
    }

    .has-no-results {
        font-style: italic;
        font-weight: $font-weight-medium;
    }

    .choices__item--selectable:after{
        display: none;
    }
}
  
.is-open{
    .choices__list--dropdown {
        border-color: $dropdown-border-color;
    }
}
  
.is-flipped{
    .choices__list--dropdown {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: 0.25rem 0.25rem 0 0;
        &.is-active {
            animation-name: DropDownSlideDown;
            transform: translateY(-1px);
        }
    }
}
  
.choices__list--dropdown{
    .choices__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
    }

    .choices__item {
        position: relative;
        font-size: $dropdown-font-size;
        padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y 16px;
    }
}
  

  
@media (min-width: 640px) {
    .choices__list--dropdown {
        .choices__item--selectable {

            &:after {
                content: attr(data-select-text);
                font-size: 12px;
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            &.is-highlighted {
                &:after {
                    opacity: 0.5;
                }
            }
        }
    }
    

}
  
.choices__item {
    cursor: default;
}
  
.choices__item--selectable {
    cursor: pointer;
}
  
.choices__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}
  
.choices__heading {
    font-weight: $font-weight-semibold;
    font-size: 12px;
    padding: 10px 16px;
    border-bottom: 1px solid var(--#{$prefix}border-color);
    color: var(--#{$prefix}secondary-color);
}
  
.choices__button {
    text-indent: -9999px;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}
  
.choices__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: $input-bg;
    color: $input-color;
    font-size: $input-font-size;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 2px 0 2px 2px;
    &:focus {
        outline: 0;
    }
    &::placeholder {
        color: $input-placeholder-color;
    }
}
  
.choices__placeholder {
    color: $input-placeholder-color;
    opacity: 1;
}


[data-bs-theme="dark"]{
    .choices[data-type*=select-one]{
        .choices__button{
            filter: $btn-close-white-filter;
        }
    }
}